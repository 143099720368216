import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoadingIndicatorHandler, NavigationService, TrackingService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { BalancePropertiesService } from '@frontend/vanilla/features/balance-properties';
import { TrustAsResourceUrlPipe } from '@frontend/vanilla/shared/browser';

import { ApiService } from '../api/api.service';
import { MvsFeaturesClientConfig, MvsGamesClientConfig, MvsPageClientConfig } from '../client-config/virtualsports-client-config.models';
import { SeoboxesComponent } from '../seoboxes/seoboxes.component';
import { GameService } from '../shared/game.service';
import { LoginDialogWrapperService } from '../shared/login-dialog-wrapper.service';
import { TeaserService } from '../shared/teaser.service';
import { VsOptimizedTeasersComponent } from '../vs-optimized-teasers/vs-optimized-teasers.component';

@Component({
    standalone: true,
    selector: 'vs-lobby',
    templateUrl: 'lobby.component.html',
    imports: [CommonModule, SeoboxesComponent, VsOptimizedTeasersComponent, TrustAsResourceUrlPipe],
})
export class LobbyComponent implements OnInit {
    iframeUrl: any;
    showGameTitle: boolean;
    isTouch: boolean;
    gameDataObservalble: any;
    lobbygames: any = [];
    gameObject: any;
    currentrelativePath: any = this.router.url;
    specificStringInCurrentUrl: any;
    currentGame: any;
    gameName: string;
    showSeo: boolean;
    displaySEOBox: boolean;
    VStitle: string;
    windowWidth: number;
    historyBack: string;
    previousGame: any = [];
    defaultGameName: any;
    private loadingIndicatorHandler: LoadingIndicatorHandler;

    constructor(
        private mvspage: MvsPageClientConfig,
        public mvsgame: MvsGamesClientConfig,
        public tracker: TrackingService,
        public features: MvsFeaturesClientConfig,
        public gameService: GameService,
        private user: UserService,
        private api: ApiService,
        private openLoginDialog: LoginDialogWrapperService,
        private router: Router,
        private balanceService: BalancePropertiesService,
        public _teaserService: TeaserService,
        private navigation: NavigationService,
    ) {
        //games:any= this.mvsgame.games;
        this.showGameTitle = this.features.showGameTitle;
        this.gameDataObservalble = this.gameService.gameData.asObservable().subscribe((gameData: any) => {
            if (gameData) {
                this.gameService.activeUrlInfo(gameData);
                this.launchGameInIframe(gameData);
                this.previousGame.push(this.currentGame);
                this.currentGame = gameData;
            }
        });
    }

    ngOnInit(): void {
        this.mvsgame.games.forEach((game: any) => {
            if (game.vendorId != 'live_sports' && game.gameName) this.lobbygames.push(game);
            if (!this.defaultGameName && game && game.vendorId && game.gameName) {
                this.defaultGameName = game;
            }
        });
        this.isTouch = window.innerWidth < 768 ? true : false;
        this.specificStringInCurrentUrl = this.currentrelativePath.substr(this.currentrelativePath.lastIndexOf('/') + 1);
        this.gameObject = this.searchItemInGameObject(this.specificStringInCurrentUrl, this.lobbygames);
        if (this.gameObject && this.specificStringInCurrentUrl) {
            this.gameService.activeUrlInfo(this.gameObject);
            this.launchGameInIframe(this.gameObject);
        } else if (this.gameObject == undefined && this.features.extendedTopMenuStatus && !this.features.extendedTopMenuToRight) {
            this.gameService.activeUrlInfo(this.defaultGameName);
            this.launchGameInIframe(this.defaultGameName);
        } else {
            this.gameService.activeUrlInfo(this.lobbygames[0]);
            this.launchGameInIframe(this.lobbygames[0]);
        }
        this.user.events.subscribe((e) => {
            if (e instanceof UserLoginEvent) {
                if (this.loadingIndicatorHandler) {
                    this.loadingIndicatorHandler.done();
                }
                this.launchGameInIframe(this.currentGame);
            }
        });
        this.displaySEOBox = this.features.enableSEOBox;
        this.VStitle = this.mvspage.messages.VSTitle;
        this.windowWidth = window.innerWidth && document.body.offsetWidth;
        this.previousGame.push(this.gameObject);
    }
    searchItemInGameObject(gameNameForUrl: any, gameList: any) {
        for (const game of gameList) {
            if (game.gameName === gameNameForUrl) {
                return game;
            }
        }
    }

    launchGameInIframe(game: any) {
        this.currentGame = game;
        if (this.user.isAuthenticated) {
            this.api.get('desktopreallauncher/' + game.vendorId + '/' + game.gameName).subscribe(
                (response: any) => {
                    if (response || response == '') {
                        this.showSeo = true;
                    }
                    this.iframeUrl = response.redirectUrl;
                },
                () => {
                    this.showSeo = true;
                },
            );
        } else {
            this.api.get('desktopplaylauncher/' + game.vendorId + '/' + game.gameName).subscribe(
                (response: any) => {
                    if (response || response == '') {
                        this.showSeo = true;
                    }
                    this.iframeUrl = response.redirectUrl;
                },
                () => {
                    this.showSeo = true;
                },
            );
        }
    }

    @HostListener('window:message', ['$event'])
    onMessage(event: any) {
        if (event.data === 'mode=refreshBal') {
            if (this.user.isAuthenticated) {
                this.balanceService.refresh();
            }
        }
    }
    @HostListener('window:popstate', ['$event'])
    onPopstate() {
        this.historyBack = location.href;
        this.previousGame.pop();
        if (this.previousGame[this.previousGame.length - 1]) {
            this.gameService.activeUrlInfo(this.previousGame[this.previousGame.length - 1]);
            this.launchGameInIframe(this.previousGame[this.previousGame.length - 1]);
        }
        this.navigation.goTo(this.historyBack);
    }

    launchGame(game: any, from: any) {
        if (this.isTouch) {
            if (this.user.isAuthenticated) this.gameService.launchGame(game, from);
            else this.openLoginDialog.open(this.getplayloginurl(game));
        } else {
            this.gameService.gameDataInfo(game);
            if (this.user.isAuthenticated) this.gameService.setOverlayVisibility(true);
            else this.openLoginDialog.open(this.getplayloginurl(game));
        }
    }
    getplayloginurl(game: any) {
        let result: string = '';
        if (!this.isTouch) {
            result =
                this.mvspage.virtualsportsPage +
                '/virtualsports/launchEmbedded/' +
                game.gameName +
                '/' +
                game.vendorId +
                '?forcedEmbeddedLaunch=true';
        } else {
            result = this.mvspage.virtualsportsPage + '/virtualsports/launchng/' + game.gameName + '/' + game.vendorId;
        }

        return result;
    }

    onResize() {
        this.isTouch = window.innerWidth < 768 ? true : false;
        this.windowWidth = window.innerWidth && document.body.offsetWidth;
    }
}
