<vs-nav *ngIf="!isOverlay"></vs-nav>
<div class="lobby">
    <vn-message-panel></vn-message-panel>
    <div id="myModal" class="modal" *ngIf="isOverlay">
        <div class="modal-content">
            <vs-embedded-game-view></vs-embedded-game-view>
        </div>
    </div>
    <vs-lobby *ngIf="!isOverlay"></vs-lobby>
</div>
