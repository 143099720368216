import { Routes } from '@angular/router';

import { routeData } from '@frontend/vanilla/core';

import { HomeComponent } from './home/home.component';
import { homeResolver } from './home/home.resolver';
import { PlayRedirectComponent } from './play-redirect.component';

export const VIRTUAL_SPORTS_ROUTES: Routes = [
    {
        path: '',
        component: HomeComponent,
        resolve: {
            initData: homeResolver,
        },
        data: routeData({ allowAnonymous: true }),
    },
    {
        path: 'launchEmbedded/:gameId/:vendorId',
        component: HomeComponent,
        data: routeData({ authorized: true }),
    },
    {
        path: 'launchng/:gameId/:vendorId',
        component: PlayRedirectComponent,
        data: routeData({ authorized: true }),
        // canActivate: [playRedirectGuard],
    },
    {
        path: ':gamename',
        component: HomeComponent,
        data: routeData({ allowAnonymous: true }),
    },
];
