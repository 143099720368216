import { Routes } from '@angular/router';

import { VANILLA_LAZY_ROUTES } from '@frontend/loaders/routes';
import { routeData } from '@frontend/vanilla/core';

import { HomeComponent } from './home/home.component';
import { homeResolver } from './home/home.resolver';
import { VIRTUAL_SPORTS_ROUTES } from './virtual-sports.routes';

export const appRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
        resolve: {
            initData: homeResolver,
        },
        data: routeData({ allowAnonymous: true }),
    },
    {
        path: '{culture}',
        children: [
            ...VANILLA_LAZY_ROUTES,
            {
                path: 'virtualsports',
                children: [...VIRTUAL_SPORTS_ROUTES],
            },
            {
                path: '',
                component: HomeComponent,
                data: routeData({ allowAnonymous: true }),
            },
            {
                path: '**',
                loadChildren: () => import('@frontend/vanilla/features/not-found').then((m) => m.ROUTES),
            },
        ],
    },
];
